import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { X } from "lucide-react";
import VisaCard from "../../../assets/images/VISA.svg";
import MasterCard from "../../../assets/images/Mastercard.svg";
import AmexCard from "../../../assets/images/AMEX.svg";
import DiscoverCard from "../../../assets/images/Discover.svg";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import PaymentForm from "./PaymentForm";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { subscribePlan } from '@/redux/thunk/paymentThunk';
import toast from "react-hot-toast";


interface FormData {
    planId: string;
    billing_cycle: string;
    payment_token: string;
    address: string;
    country: string;
}

interface PaymentModalProps {
    open: boolean;
    onClose: () => void;
    plan: any; // You can define the exact type for `plan` if necessary
    type: string; // "monthly" or "yearly"
    formData: FormData;
    setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}
interface SubscribePlanPayload {
    planId: string;
    billing_cycle: string;
    payment_token: string;
    address: string;
    country: string;
}

const PaymentModal: React.FC<PaymentModalProps> = ({ open, onClose, plan, type, formData, setFormData }) => {
    if (!open) return null;
    const dispatch = useAppDispatch();
    const { isSubscribing, subscriptionMessage, subscriptionSuccess } = useAppSelector(
        (state) => state.payment
    );
   
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const validateForm = () => {
        let newErrors: { [key: string]: string } = {};
        // if (!formData.first_name) newErrors.first_name = "First name is required";
        // if (!formData.last_name) newErrors.last_name = "Last name is required";
        if (!formData.country) newErrors.country = "Country is required";
        if (!formData.address) newErrors.address = "Address is required";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if no errors
    };

    const handleConfirm = () => {
        if (validateForm()) {
            const payload: SubscribePlanPayload = {
                planId: formData.planId,
                billing_cycle: formData.billing_cycle,
                payment_token: formData.payment_token,
                address: formData.address,
                country: formData.country,
            };

            dispatch(subscribePlan(payload));
        }
    };
    useEffect(() => {
        if (!isSubscribing && subscriptionSuccess) {
            toast.success(subscriptionMessage);
            onClose();  // Close the modal
        } else if (!isSubscribing && subscriptionSuccess === false) {
            toast.error(subscriptionMessage || "Subscription failed.");
        }
    }, [subscriptionSuccess, subscriptionMessage, isSubscribing, onClose]);
    return (
        <Box sx={overlayStyle} >
            <Box sx={modalStyle} onClick={(e) => e.stopPropagation()}>
                <Box sx={CardHeaderContainer}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography variant='h6'>
                            Confirm Payment
                        </Typography>
                        <Button onClick={onClose} sx={{ width: "40px", height: "40px", }}>
                            <X height={20} width={20} />
                        </Button>
                    </Box>

                    <Box sx={CardsContainer}>
                        <img
                            alt='VISA'
                            src={VisaCard}
                            style={{ height: "24px", width: "36px" }}
                        />
                        <img
                            alt='Master'
                            src={MasterCard}
                            style={{ height: "24px", width: "36px" }}
                        />
                        <img
                            alt='AMEX'
                            src={AmexCard}
                            style={{ height: "24px", width: "36px" }}
                        />
                        <img
                            alt='Discover'
                            src={DiscoverCard}
                            style={{ height: "24px", width: "36px" }}
                        />
                    </Box>
                </Box>
                <Box sx={PlanContainer}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column", gap: "4px"
                    }}>
                        <Typography
                            variant="h3"
                            sx={{

                                color: "#1E293B",
                            }}
                        >
                            {plan?.name}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            sx={{ color: "#667085" }}
                        >
                            {plan?.description}
                        </Typography>
                    </Box>
                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: "48px",
                            color: "#1E293B",
                        }}
                    >$
                        {type === "monthly" ? plan?.monthly_price : plan?.yearly_price}
                        <span style={{ fontSize: "16px", fontWeight: "500", color: "#667085" }}>{type === "monthly" ? "/mo" : "/Year"}</span>
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", overflowY: "auto", paddingRight: "4px", gap: "24px" }}>
                    <Box sx={{ display: "flex", gap: "24px" }}>
                        <Box sx={formGroup}>
                            <Typography variant="subtitle1" sx={{ fontWeight: "500", color: "#1E293B" }}>
                                First Name
                            </Typography>
                            <input
                                type="text"
                                // name="first_name"
                                // value={formData.first_name || ""}
                                // onChange={handleChange}
                                style={inputStyle}
                            />
                        </Box>
                        <Box sx={formGroup}>
                            <Typography variant="subtitle1" sx={{ fontWeight: "500", color: "#1E293B" }}>
                                Last Name
                            </Typography>
                            <input
                                type="text"
                                name="last_name"
                                // value={formData.last_name || ""}
                                // onChange={handleChange}
                                style={inputStyle}
                            />
                        </Box>
                    </Box>
                    <PaymentForm setFormData={setFormData} />
                    <Box sx={{ display: "flex", gap: "24px" }}>
                        <Box sx={formGroup}>
                            <Typography variant="subtitle1" sx={{ fontWeight: "500", color: "#1E293B" }}>
                                Country
                            </Typography>
                            <input
                                type="text"
                                name="country"
                                value={formData.country || ""}
                                onChange={handleChange}
                                style={inputStyle}
                            />
                            {errors.country && <Typography variant='caption' sx={errorText}>{errors.country}</Typography>}
                        </Box>
                        <Box sx={formGroup}>
                            <Typography variant="subtitle1" sx={{ fontWeight: "500", color: "#1E293B" }}>
                                Address
                            </Typography>
                            <input
                                type="text"
                                name="address"
                                value={formData.address || ""}
                                onChange={handleChange}
                                style={inputStyle}
                            />
                            {errors.address && <Typography variant='caption' sx={errorText}>{errors.address}</Typography>}
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "end", gap: "8px" }}>
                    <Button sx={{
                        width: "fit-content", padding: "8px 14px", backgroundColor: "#FFFFFF", borderRadius: "6px", display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid #7C4CE1", ":hover": {
                            backgroundColor: "#E0DAF9",
                        },
                    }}
                        onClick={onClose}
                    >
                        <Typography variant="caption" sx={{ color: "#7C4CE1", fontWeight: "500" }}>
                            Back
                        </Typography>
                    </Button>
                    <PrimaryButton
                        loading={isSubscribing}
                        style={{
                            width: { xs: "100%", sm: "auto" },
                            minWidth: "90px",
                            height: "30px"
                        }}
                        compLeft={true}
                        text={"Confirm"}
                        onClick={handleConfirm}
                    />

                </Box>
            </Box>
        </Box>
    );
}

export default PaymentModal;

const errorText = {
    color: "red",
    fontSize: "12px"
};
const formGroup = {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    width: "100%"
}
const inputStyle = {
    width: "100%",
    padding: "10px",
    borderRadius: "6px",
    border: "1px solid #D1D5DB",
    fontSize: "16px",
    color: "#1E293B",
    outline: "none",
    height: "40px"
};
const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1110,
};

const modalStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    padding: "32px",
    borderRadius: "20px",
    backgroundColor: "#FFFFFF",
    width: "600px",
    maxHeight: "90vh",

};

const CardHeaderContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "16px"

}

const CardsContainer = {
    display: "flex",
    gap: "8px"
}

const PlanContainer = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
}