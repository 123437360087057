import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../services/http.service";
import axios from "axios";
import { store } from "../store";
import { setAppForbidden } from "../slices/servicesSlice";

interface promptResponse {
  prompt: string;
  message: string;
  response: {};
  projectId: string;
}
interface getgeneratedPromptResponse {
  prompt: string;
  message: string;
  response: {};
  projectId: string;
  project: any;
  role: string;
}
interface updateViaPromptResponse {
  prompt: string;
  message: string;
  response: {};
  projectId: string;
  modelOutput: any;
}
interface SaveUpdatedCodeResponse {
  message: string;
  response: any;
}

interface generateFullStackAppResponse {
  message: string;
  response: any;
  project: any;
}
interface updateViaAIRegenerateResponse {
  prompt: string;
  message: string;
  response: {};
  projectId: string;
  modelOutput: any;
}
interface templateSuggestionsResponse {
  message: string;
  response: any;
  templates: any[];
}

export const generatePrompt = createAsyncThunk<
  promptResponse,
  {
    prompt: string;
    type: string;
    aspectRatio: string;
    name: string;
    template?: string;
  },
  { rejectValue: any }
>(
  "project/generate",
  async (
    data: {
      prompt: string;
      type: string;
      aspectRatio: string;
      name: string;
      template?: string;
    },
    thunkAPI
  ) => {
    const language = store.getState().auth.auth.i18nextLng;
    const [response, error] = await http.post("/project/generate", {
      ...data,
      language: language,
    });
    if (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
    return response.data;
  }
);
export const generateCode = createAsyncThunk<
  promptResponse,
  {
    prompt: string;
    type: string;
    aspectRatio: string;
    name: string;
    template?: string;
  },
  { rejectValue: any }
>(
  "project/generate-code",
  async (
    data: {
      prompt: string;
      type: string;
      aspectRatio: string;
      name: string;
      template?: string;
    },
    thunkAPI
  ) => {
    const language = store.getState().auth.auth.i18nextLng;
    const [response, error] = await http.post("/project/generate-code", {
      ...data,
      language: language,
    });
    if (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
    return response.data;
  }
);

export const getgeneratedPrompt = createAsyncThunk<
  getgeneratedPromptResponse,
  { projectId: string },
  { rejectValue: any }
>("project/getgenerated", async ({ projectId }, thunkAPI) => {
  const [response, error] = await http.get(`/project/${projectId}`);
  if (error) {
    if (error?.response?.status === 403) {
      store.dispatch(setAppForbidden(true));
    }
    return thunkAPI.rejectWithValue(error.response.data);
  }
  return response.data;
});

export const updateViaPrompt = createAsyncThunk<
  updateViaPromptResponse,
  {
    prompt: string;
    projectId: string;
    htmlId: string;
  },
  { rejectValue: any }
>(
  "updateViaPrompt",
  async (
    data: {
      prompt: string;
      projectId: string;
      htmlId: string;
    },
    thunkAPI
  ) => {
    const [response, error] = await http.patch(
      `/project/update/${data.projectId}`,
      { prompt: data.prompt, htmlId: data.htmlId }
    );
    if (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
    return response.data;
  }
);

export const SaveUpdatedCode = createAsyncThunk<
  SaveUpdatedCodeResponse,
  {
    files: { [key: string]: any };
    id: string;
  },
  { rejectValue: any }
>(
  "project/save",
  async (
    data: {
      files: { [key: string]: any };
      id: string;
    },
    thunkAPI
  ) => {
    const [response, error] = await http.post(`project/save/${data.id}`, {
      code: data?.files,
    });
    if (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
    return response.data;
  }
);

export const generateFullStackApp = createAsyncThunk<
  generateFullStackAppResponse,
  {
    prompt: string;
    userId: string;
  },
  { rejectValue: any }
>(
  "project/generatFullStack",
  async (
    data: {
      prompt: string;
      userId: string;
    },
    thunkAPI
  ) => {
    try {
      const response = await axios.post(
        `${
          import.meta.env.VITE_APP_BASE_URL_DEV_NEST
        }projects/generate/fullstack/claude`,
        data
      );
      return response.data; // Return the response if successful
    } catch (error: any) {
      // Error handling
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "An error occurred"
      );
    }
  }
);

export const updateViaAIRegenerate = createAsyncThunk<
  updateViaAIRegenerateResponse,
  {
    projectId: string;
    htmlId: string;
  },
  { rejectValue: any }
>(
  "updateViaPrompt/regenerate-with-ai",
  async (
    data: {
      projectId: string;
      htmlId: string;
    },
    thunkAPI
  ) => {
    const [response, error] = await http.patch(
      `/project/regenerate-with-ai/${data.projectId}`,
      { htmlId: data.htmlId }
    );
    if (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
    return response.data;
  }
);

//  template suggestion

export const templateSuggestions = createAsyncThunk<
  templateSuggestionsResponse,
  {
    prompt: string;
  },
  { rejectValue: any }
>(
  "project/templateSuggestion",
  async (
    data: {
      prompt: string;
    },
    thunkAPI
  ) => {
    const [response, error] = await http.post(
      `/project/get-suggested-templates`,
      { prompt: data.prompt }
    );
    if (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
    return response.data;
  }
);
//template change

export const changeTemplate = createAsyncThunk<
  templateSuggestionsResponse,
  {
    templateId: string;
    projectId: string;
  },
  { rejectValue: any }
>(
  "project/change-template",
  async (
    data: {
      templateId: string;
      projectId: string;
    },
    thunkAPI
  ) => {
    const [response, error] = await http.post(
      `/project/change-template/${data.projectId}`,
      { templateId: data.templateId }
    );
    if (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
    return response.data;
  }
);
