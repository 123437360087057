import { Box, Button, useTheme } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import {
  LogoWithOutTextImage,
  playEditorIcon,
  styleEditorIcon,
} from "@/assets/images";
import { Link, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useEffect } from "react";
import ToolSidebar from "./components/ToolSidebar";
import { setPrototypeModel } from "@/redux/slices/userSlice";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import ShareIcon from "@mui/icons-material/Share";
import UpdateProjectName from "./components/UpdateProjectName";
import { useTranslation } from "react-i18next";
import { clearChat } from "@/redux/slices/chatbotSlice";
import toast from "react-hot-toast";
import SaveFuncationality from "./components/SaveFuncationality";
import ProfileMenu from "./components/ProfileMenu";
import LanguageDropdown from "@/components/languageDropdown";
import EditorMenuButton from "@/components/buttons/EditorMenuButton";
import EditorOtherMenuButton from "@/components/buttons/EditorOtherMenuButton";
import ExportMenuButton from "@/components/buttons/ExportMenuButton";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import CustomModel from "@/components/model/CustomModel";
import AIEditorToggle from "./components/AIEditorToggle";
import UpgradeModal from "@/components/model/UpgradeModal";
import { getUserDetails } from "@/redux/thunk/userThunk";

interface BuilderNavbarProps {
  location: any;
}

const BuilderNavbar: React.FC<BuilderNavbarProps> = (props) => {
  const { location } = props;
  const naviagte = useNavigate();
  const theme = useTheme(); // Access the theme for consistency
  const [showSaving, setShowSaving] = React.useState(true);
  const [display, setDisplay] = React.useState(false);
  const [displayDashboard, setDisplayDashboard] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { projectRole, saveProjectStatus, saveProjectLoader, fullStackData } =
    useAppSelector((state) => state.prompt);
  const { prototypeModel } = useAppSelector((state) => state.user);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  useEffect(() => {
    if (location.pathname.startsWith("/icod-builder")) {
      setDisplay(true);
      setDisplayDashboard(false);
    } else if (
      location.pathname === "/my-projects" ||
      location.pathname === "/profile" ||
      location.pathname === "/shared-projects"
    ) {
      setDisplayDashboard(true);
      setDisplay(false);
      dispatch(clearChat());
    } else {
      setDisplay(false);
      setDisplayDashboard(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (saveProjectStatus === true) {
      const timer = setTimeout(() => {
        setShowSaving(false);
      }, 2000); // Hide after 2 seconds
      return () => clearTimeout(timer); // Cleanup timer on unmount or on dependency change
    }
  }, [saveProjectStatus]);
  useEffect(() => {
    dispatch(getUserDetails());
  }, []);
  const handleAnchorClick = () => {
    if (saveProjectStatus === true) {
      naviagte("/my-projects");
    } else {
      toast(
        (el) => (
          <span>
            {t("utalities.Yo_have_unsaved_changes")}
            <br />
            <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button variant="contained" onClick={() => toast.dismiss(el.id)}>
                Close
              </Button>
              <Button
                sx={{ ml: "12px" }}
                onClick={() => {
                  toast.dismiss(el.id);
                  naviagte("/my-projects");
                }}
              >
                Go anyway
              </Button>
            </div>
          </span>
        ),
        {
          duration: 5000,
          style: {
            minWidth: "250px",
            maxWidth: "450px",
          },
        }
      );
    }
  };

  return (
    <Box sx={FirstBoxStyle(location, theme)}>
      <Box sx={ContainerStyle}>
        <Box sx={BoxStyle}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {location.pathname.startsWith(
              "/builder-webApp"
            ) ? null : location.pathname.startsWith("/icod-builder") ? (
              <Box
                onClick={handleAnchorClick}
                sx={{
                  height: "34px",
                  width: "32px",
                  mx: "auto",
                  my: "16px",
                  cursor: "pointer",
                }}
              >
                {saveProjectStatus ? (
                  <Link
                    to="/my-projects"
                    style={{
                      display: "block",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <img
                      src={LogoWithOutTextImage}
                      alt="logo"
                      style={{ height: "100%", width: "100%" }}
                    />
                  </Link>
                ) : (
                  <img
                    src={LogoWithOutTextImage}
                    alt="logo"
                    style={{ height: "100%", width: "100%" }}
                  />
                )}
              </Box>
            ) : null}
            {location.pathname.startsWith("/icod-builder") && (
              <Box
                sx={{
                  backgroundColor: "#DFE4EA",
                  width: "1.5px",
                  height: "32px",
                  mx: "12px",
                }}
              />
            )}

            {(display || displayDashboard) && (
              <Box
                sx={{
                  display: { xs: "block", md: "none" },
                  height: "24px",
                  width: "24px",
                  mr: "8px",
                }}
              >
                <MenuIcon
                  sx={{ color: "#7C4CE1" }}
                  onClick={toggleDrawer(true)}
                />
              </Box>
            )}
            {!location.pathname.startsWith("/builder-webApp") &&
              ["owner", "admin", "editor"].includes(projectRole) &&
              display &&
              !prototypeModel && <AIEditorToggle />}
            {/* canvas */}
            {!location.pathname.startsWith("/builder-webApp") &&
              ["owner", "admin", "editor"].includes(projectRole) &&
              display &&
              !prototypeModel && (
                <Box
                  sx={{ display: { xs: "none", md: "block" }, mx: "12px" }}
                  className="icod-canvas-code-toolbar-controls"
                >
                  <EditorMenuButton imgSrc={styleEditorIcon} title={"Style"} />
                </Box>
              )}
          </Box>

          {/* Update Project Name */}
          {display && !prototypeModel && (
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <UpdateProjectName />
            </Box>
          )}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {/* save functionality */}
            {display && (
              <SaveFuncationality
                saveProjectLoader={saveProjectLoader}
                saveProjectStatus={saveProjectStatus}
                showSaving={showSaving}
                t={t}
                dispatch={dispatch}
              />
            )}
            {location.pathname.startsWith("/icod-builder") && (
              <Box
                sx={{
                  ml: "24px",
                  mr: "12px",
                  height: "42px",
                  width: "1.5px",
                  backgroundColor: "#DFE4EA",
                  display: { xs: "none", md: "block" },
                }}
              />
            )}
            {display && (
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={() => {
                  dispatch(setPrototypeModel(prototypeModel ? false : true));
                }}
                sx={{
                  height: "32px",
                  mr: "12px",
                  minWidth: "40px",
                  bgcolor: "transport",
                  borderRadius: "6px",
                  textTransform: "none",
                  color: "text.primary",
                  display: { xs: "none", md: "block" },
                  "&:hover": {
                    bgcolor: "#E5E7EB",
                  },
                }}
              >
                <img src={playEditorIcon} alt="preview" />
              </Button>
            )}
            {["owner", "admin"].includes(projectRole) &&
              display &&
              !prototypeModel && (
                <Box
                  className="icod-export-and-deploy-button"
                  sx={{ display: { xs: "none", md: "block" } }}
                >
                  <ExportMenuButton />
                </Box>
              )}
            {/* Share button */}
            {location.pathname.startsWith("/builder-webApp") && (
              <>
                <CustomModel
                  title="App Credentials"
                  buttonTitle="Credential"
                  data={fullStackData?.appAdminDetails}
                />
                <Button
                  variant="outlined"
                  startIcon={
                    <ShareIcon sx={{ height: "16px", width: "20px" }} />
                  }
                  sx={PreviewButtonStyle}
                  onClick={() => window.open(fullStackData?.url, "_blank")}
                >
                  {t("Header.Share")}
                </Button>
              </>
            )}

            {location?.pathname?.startsWith("/icod-builder") ||
              location.pathname.startsWith("/builder-webApp") ? null : (
              <LanguageDropdown />
            )}
            {displayDashboard && (
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  alignItems: "center",
                }}
              >
                <PrimaryButton
                  style={{ display: "flex" }}
                  compLeft={true}
                  text={t("Header.CreateProject")}
                  onClick={() => {
                    naviagte(`/create-website`);
                  }}
                  imageComponent={
                    <AddRoundedIcon
                      sx={{
                        marginRight: "8px",
                        height: "20px",
                        width: "20px",
                      }}
                    />
                  }
                />
              </Box>
            )}
            {location?.pathname?.startsWith("/icod-builder") ||
              location.pathname.startsWith("/builder-webApp") ? null : (
              <ProfileMenu />
            )}
            {location?.pathname?.startsWith("/icod-builder") && (
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <EditorOtherMenuButton />
              </Box>
            )}
          </Box>
        </Box>
        <ToolSidebar open={open} toggleDrawer={toggleDrawer} />
      </Box>
      <UpgradeModal />
    </Box>
  );
};

export default BuilderNavbar;

const ContainerStyle = {
  height: "54px",
  position: "sticky",
  top: 0,
  width: "100%",
};

const BoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "100%",
  width: "100%",
  pr: "16px",
  pl: "12px",
};

const PreviewButtonStyle = {
  borderColor: "#fff",
  display: { xs: "none", md: "flex" },
  mx: "8px",
  border: "2px solid #7C4CE1",
  color: "#7C4CE1",
};
const FirstBoxStyle = (location, theme) => ({
  backgroundColor:
    location.pathname === "/create-website"
      ? "#7C4CE1"
      : theme?.palette?.background?.paper,
  borderBottom:
    location.pathname === "/create-website"
      ? "none"
      : `1px solid ${theme?.palette?.background?.neutral}`,
  zIndex: 1200,
  height: "54px",
  width: "100%",
  px:
    location.pathname === "/create-website"
      ? { xs: "0", sm: "6px", md: "12px" }
      : "0",
});
